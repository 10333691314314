import { create } from '@wix/fedops-logger';
import { property } from 'lodash';
import { utils } from '@wix/pro-gallery-tpa-wrapper/dist/src/utils';
import experiments from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import { PG_APP_DEFINITION_ID, GALLERY_WIDGET_ID } from '../constants';

export default class ProGalleryStore {
  constructor(context, wixCodeApi, platformServices, isSSR) {
    this.context = context;
    this.wixCodeApi = wixCodeApi;
    this.getGalleryDataFromServer = this.getGalleryDataFromServer.bind(this);
    this.getItemsFromServer = this.getItemsFromServer.bind(this);
    this.setItems = this.setItems.bind(this);
    this.getItems = this.getItems.bind(this);
    this.onItemClicked = this.onItemClicked.bind(this);
    this.pgRenderStart = Date.now();

    try {
      this.createFedopsLoggger(platformServices, isSSR);
      this.fedopsLogger.appLoadStarted();
    } catch (e) {
      console.error('Cannot create fedops logger', e);
      this.fedopsLogger = {
        appLoaded: () => {},
        appLoadStarted: () => {},
        appPartiallyLoaded: () => {},
      };
    }
  }

  createFedopsLoggger = (platformServices, isSSR) => {
    let useNewFedopsLogger = false;
    try {
      if (
        this.wixCodeApi.location.query.useNewFedopsLogger === 'true' ||
        experiments['specs.pro-gallery.useNewFedopsLogger'] === 'true'
      ) {
        useNewFedopsLogger = true;
      }
    } catch (e) {
      console.log('Cannot get query param useNewFedopsLogger', e);
    }
    if (useNewFedopsLogger) {
      const fedOpsLoggerFactory = platformServices.fedOpsLoggerFactory;
      this.fedopsLogger = fedOpsLoggerFactory.getLoggerForWidget({
        appId: PG_APP_DEFINITION_ID,
        widgetId: GALLERY_WIDGET_ID,
      });
    } else {
      this.fedopsLogger = create('pro-gallery-ooi', {
        isWidgetLogger: true,
        isServerSide: isSSR,
        appId: PG_APP_DEFINITION_ID,
        metasiteId: platformServices.bi.msid,
        widgetId: GALLERY_WIDGET_ID,
      });
    }
  };

  onAppLoaded = () => {
    try {
      this.fedopsLogger.appLoaded();
    } catch (e) {
      if (utils.isVerbose()) {
        console.log('cant run fedopsLogger.appLoaded()');
      }
    }
  };

  static getBaseUrl(api) {
    const baseUrl = api.location.baseUrl;
    const baseUrlParts = baseUrl.split('/');
    const origin = baseUrlParts.slice(0, 3).join('/');
    return origin;
  }

  static getExperiments(baseApi) {
    // only under scope `pro-gallery-viewer`
    const baseUrl = ProGalleryStore.getBaseUrl(baseApi);
    return fetch(`${baseUrl}/_api/pro-gallery-webapp/v1/viewer/experiments`, {
      credentials: 'include',
    })
      .then(res => res.json())
      .then(res => res.experiments)
      .catch(() => {
        return {};
      });
  }

  static getTranslations() {
    // only under scope `pro-gallery-viewer`
    return fetch(
      'https://static.parastorage.com/services/pro-gallery-statics/2.854.0/assets/locale/pro-gallery/Gallery/Gallery_en.json',
    )
      .then(res => res.json())
      .catch(() => {
        return {};
      });
  }

  getGalleryDataFromServer(from, options = {}) {
    const { isSSR = false } = options;
    const baseUrl = ProGalleryStore.getBaseUrl(this.wixCodeApi);
    const to = from + (from > 0 ? 100 : 50); //start with 50 items
    const {
      externalId = '00000000-0000-0000-0000-000000000000',
      compId,
      instanceId,
    } = this.context;
    let itemsUrl = `${baseUrl}/_api/pro-gallery-webapp/v1/gallery/${instanceId}/items/from/${from}/to/${to}?compId=${compId}&externalId=${externalId}`;
    if (isSSR) {
      itemsUrl += '&petri_ovr=specs.SkipCachePopulationSpec:true';
    }

    if (utils.isVerbose()) {
      console.log('Getting items from server', from, to, itemsUrl);
    }
    return fetch(itemsUrl)
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404 && isSSR) {
          if (utils.isVerbose()) {
            console.log('first publish');
          }
          return [];
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch(e => {
        console.error('Could not get items from server', e);
        return [];
      });
  }

  getItemsFromServer(from) {
    return this.getGalleryDataFromServer(from).then(res => {
      return res.pageResponse.items;
    });
  }

  onItemClicked(itemData) {
    const link = property('dto.metaData.link')(itemData);
    try {
      if (link) {
        if (link.type === 'web') {
          this.wixCodeApi.location.to(link.url);
        } else if (link.type === 'page') {
          try {
            const pageUrl = this.wixCodeApi.site
              .getSiteStructure()
              .pages.find(page => page.id === link.url).url;
            this.wixCodeApi.location.to(pageUrl);
          } catch (e) {
            console.warn('Cannot navigate to page', e);
          }
        } else if (link.type === 'wix') {
          const linkData = property('dto.metaData.link.data')(itemData);
          if (linkData) {
            const externalUrl = this.wixCodeApi.location.getExternalUrl(
              linkData,
            );
            if (!externalUrl) {
              this.wixCodeApi.location.navigateTo(linkData);
            } else {
              console.error('this should have been handled by <a href>');
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  setItems(items) {
    if (items && items.length >= 0) {
      this.items = items.map(item => {
        let directLink = {
          url: undefined,
          target: undefined,
        };
        if (item.metaData && item.metaData.link) {
          const link = item.metaData.link;
          if (link.type === 'web' && typeof link.url === 'string') {
            const isExternal =
              link.url.slice(0, 4) === 'http' || link.url.slice(0, 2) === '//';
            if (isExternal) {
              directLink = {
                url: link.url,
                target: '_blank',
              };
            }
          } else if (link.type === 'wix') {
            const linkData = link.data;
            if (linkData) {
              try {
                const externalUrl = this.wixCodeApi.location.getExternalUrl(
                  linkData,
                );
                if (externalUrl) {
                  directLink = {
                    url: externalUrl,
                    target: linkData.target || '_self',
                  };
                }
              } catch (e) {
                console.error(e);
              }
            }
          }
        }
        item.directLink = directLink;
        return item;
      });
    } else {
      console.error('corrupt items', items, 'returning old items instead');
      return this.items;
    }
  }

  getItems() {
    return this.items || [];
  }

  addItems(items, from) {
    const curItems = this.getItems();
    this.setItems(curItems.slice(0, from).concat(items)); //do not double append items
  }

  loadInitialItems(isSSR) {
    if (utils.isVerbose()) {
      console.log(
        'Getting initial items from server',
        this.items,
        this.totalItemsCount,
      );
    }
    if (this.items && this.items.length > 0) {
      //items were already loaded by wix-code or warmup data
      return Promise.resolve(this.items);
    } else {
      let itemsPromise;
      try {
        itemsPromise = this.getGalleryDataFromServer(0, { isSSR });
      } catch (e) {
        console.error('couldnt get items', e);
        itemsPromise = Promise.resolve({
          pageResponse: {
            totalItemsCount: 0,
            items: [],
          },
        });
      }

      return itemsPromise.then(response => {
        if (utils.isVerbose()) {
          console.log('Got initial items from server', response);
        }
        try {
          const { galleryId, dateCreated } = response;
          const { items, settings, totalItemsCount } = response.pageResponse;
          Object.assign(this, { galleryId, dateCreated, settings }); //parse everything
          this.setItems(items);
          this.totalItemsCount = Number(totalItemsCount);
        } catch (e) {
          console.error(
            'Could not fetch initial items from server',
            e,
            response,
          );
          this.totalItemsCount = 1;
        }
        return this.getItems();
      });
    }
  }

  pageReady(setProps) {
    this.setProps = setProps;
  }
}
